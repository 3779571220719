.carousel{
    overflow:hidden;
    display:flex;
    flex-direction: column;
    gap: 2rem;
}

.container{
    display: flex;
    align-items: flex-start;

    .slide{
        flex: 0 0 100%;
    }
}

.dots{
    display: flex;
    justify-content: center;
    gap: 10px;

    .dot{
        background-color: #CECECE;
        width: 10px;
        height: 10px;
        border-radius: 50%;

        &.selected{
            background-color: #000;
        }
    }
}