.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  cursor: pointer;
  font-weight: var(--button-font-weight);
  font-size: 1.25rem;
  transition: all var(--transition-duration) var(--transition-timing-function);
  gap: 1rem;
  width: max-content;
  max-width: 100%;
  font-weight: 300;
  white-space: nowrap;

  .btn-icon-right {
    transition: all var(--transition-duration) var(--transition-timing-function);
    transform: translateX(0);
  }

  &:hover {
    .btn-icon-right {
      trasition-delay: 0.1s;
      transform: translateX(5px);
    }
  }
}

.btn-primary {
  border-color: #000;
  color: #000;

  &.light {
    border-color: #fff;
    color: #fff;
  }
}

.btn-primary:hover {
  background-color: #000;
  color: #fff;
  &.light {
    background-color: #fff;
    color: #000;
  }
}

.btn-secondary {
  background-color: #000;
  border-color: #000;
  color: #fff;
}

.btn-primary:active {
  background-color: var(--primary-hover-color);
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}

.btn-primary:disabled {
  opacity: 0.75;
  cursor: not-allowed;
}

.btn-small {
  height: 3rem;
  padding: 1rem;
}

.btn-medium {
  height: 4rem;
  padding: 2rem;
}

.btn-large {
  height: 3rem;
  padding: 0.75rem;
}

.btn:focus {
  outline: none;
  box-shadow: 0 0 0 3px var(--gold-color);
}
