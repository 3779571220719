.work {
}

.work-article {
  grid-template-columns: 204px auto;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
}
